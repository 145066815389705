<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" class="container" ngNativeValidate>
    <div class="row justify-content-md-center mt-5">
        <div class="col-5">
            <img src="images/logo-dark@2x.png" class="logo mb-2" alt="Bitwarden">
            <p class="lead text-center mx-4 mb-4">{{'loginOrCreateNewAccount' | i18n}}</p>
            <div class="card d-block">
                <div class="card-body">
                    <div class="form-group">
                        <label for="email">{{'emailAddress' | i18n}}</label>
                        <input id="email" class="form-control" type="text" name="Email" [(ngModel)]="email" required inputmode="email" appInputVerbatim="false">
                    </div>
                    <div class="form-group">
                        <label for="masterPassword">{{'masterPass' | i18n}}</label>
                        <div class="d-flex">
                            <input id="masterPassword" type="{{showPassword ? 'text' : 'password'}}" name="MasterPassword" class="text-monospace form-control"
                                [(ngModel)]="masterPassword" required appInputVerbatim>
                            <button type="button" class="ml-1 btn btn-link" title="{{'toggleVisibility' | i18n}}" (click)="togglePassword()">
                                <i class="fa fa-lg" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
                            </button>
                        </div>
                        <small class="form-text">
                            <a routerLink="/hint">{{'getMasterPasswordHint' | i18n}}</a>
                        </small>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="rememberEmail" name="RememberEmail" [(ngModel)]="rememberEmail">
                        <label class="form-check-label" for="rememberEmail">{{'rememberEmail' | i18n}}</label>
                    </div>
                    <hr>
                    <div class="d-flex">
                        <button type="submit" class="btn btn-primary btn-block btn-submit" [disabled]="form.loading">
                            <span>
                                <i class="fa fa-sign-in"></i> {{'logIn' | i18n}}
                            </span>
                            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                        </button>
                        <a routerLink="/register" [queryParams]="{email: email}" class="btn btn-outline-secondary btn-block ml-2 mt-0">
                            <i class="fa fa-pencil-square-o"></i> {{'createAccount' | i18n}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
