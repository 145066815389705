<div class="modal fade">
    <div class="modal-dialog">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
            <div class="modal-header">
                <h2 class="modal-title">{{'deleteOrganization' | i18n}}</h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'deleteOrganizationDesc' | i18n}}</p>
                <app-callout type="warning">{{'deleteOrganizationWarning' | i18n}}</app-callout>
                <label for="masterPassword">{{'masterPass' | i18n}}</label>
                <input id="masterPassword" type="password" name="MasterPasswordHash" class="form-control" [(ngModel)]="masterPassword" required
                    appAutofocus appInputVerbatim>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger btn-submit" [disabled]="form.loading">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                    <span>{{'deleteOrganization' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
