<div class="page-header">
    <h1>{{'passwordGenerator' | i18n}}</h1>
</div>
<div class="card card-password bg-light my-4">
    <div class="card-body" [innerHTML]="password | colorPassword"></div>
</div>
<div class="form-group">
    <div class="form-check form-check-inline">
        <input id="generate-password" name="type" value="password" class="form-check-input" type="radio" (change)="saveOptions()"
            [(ngModel)]="options.type">
        <label for="generate-password" class="form-check-label">{{'password' | i18n}}</label>
    </div>
    <div class="form-check form-check-inline">
        <input id="generate-passphrase" name="type" value="passphrase" class="form-check-input" type="radio" (change)="saveOptions()"
            [(ngModel)]="options.type">
        <label for="generate-passphrase" class="form-check-label">{{'passphrase' | i18n}}</label>
    </div>
</div>
<div class="row" *ngIf="options.type === 'passphrase'">
    <div class="form-group col-4">
        <label for="num-words">{{'numWords' | i18n}}</label>
        <input id="num-words" class="form-control" type="number" min="3" max="20" [(ngModel)]="options.numWords" (blur)="saveOptions()">
    </div>
    <div class="form-group col-4">
        <label for="word-separator">{{'wordSeparator' | i18n}}</label>
        <input id="word-separator" class="form-control" type="text" maxlength="1" [(ngModel)]="options.wordSeparator"
            (blur)="saveOptions()">
    </div>
</div>
<ng-container *ngIf="options.type === 'password'">
    <div class="row">
        <div class="form-group col-4">
            <label for="length">{{'length' | i18n}}</label>
            <input id="length" class="form-control" type="number" min="5" max="128" [(ngModel)]="options.length" (blur)="saveOptions()">
        </div>
        <div class="form-group col-4">
            <label for="min-number">{{'minNumbers' | i18n}}</label>
            <input id="min-number" class="form-control" type="number" min="0" max="9" (input)="saveOptions()"
                [(ngModel)]="options.minNumber">
        </div>
        <div class="form-group col-4">
            <label for="min-special">{{'minSpecial' | i18n}}</label>
            <input id="min-special" class="form-control" type="number" min="0" max="9" (input)="saveOptions()"
                [(ngModel)]="options.minSpecial">
        </div>
    </div>
    <div class="form-group">
        <div class="form-check">
            <input id="uppercase" class="form-check-input" type="checkbox" (change)="saveOptions()" [(ngModel)]="options.uppercase">
            <label for="uppercase" class="form-check-label">A-Z</label>
        </div>
        <div class="form-check">
            <input id="lowercase" class="form-check-input" type="checkbox" (change)="saveOptions()" [(ngModel)]="options.lowercase">
            <label for="lowercase" class="form-check-label">a-z</label>
        </div>
        <div class="form-check">
            <input id="numbers" class="form-check-input" type="checkbox" (change)="saveOptions()" [(ngModel)]="options.number">
            <label for="numbers" class="form-check-label">0-9</label>
        </div>
        <div class="form-check">
            <input id="special" class="form-check-input" type="checkbox" (change)="saveOptions()" [(ngModel)]="options.special">
            <label for="special" class="form-check-label">!@#$%^&amp;*</label>
        </div>
        <div class="form-check">
            <input id="ambiguous" class="form-check-input" type="checkbox" (change)="saveOptions()" [(ngModel)]="avoidAmbiguous">
            <label for="ambiguous" class="form-check-label">{{'ambiguous' | i18n}}</label>
        </div>
    </div>
</ng-container>
<div class="d-flex">
    <div>
        <button type="button" class="btn btn-primary" (click)="regenerate()">
            {{'regeneratePassword' | i18n}}
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="copy()">
            {{'copyPassword' | i18n}}
        </button>
    </div>
    <div class="ml-auto">
        <button type="button" class="btn btn-outline-secondary" (click)="history()" title="{{'passwordHistory' | i18n}}">
            <i class="fa fa-clock-o fa-lg"></i>
        </button>
    </div>
</div>
<ng-template #historyTemplate></ng-template>
