<div class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">
                    {{'twoStepLogin' | i18n}}
                    <small>{{'emailTitle' | i18n}}</small>
                </h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <app-two-factor-verify [organizationId]="organizationId" [type]="type" (onAuthed)="auth($event)" *ngIf="!authed">
            </app-two-factor-verify>
            <form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate *ngIf="authed">
                <div class="modal-body">
                    <ng-container *ngIf="enabled">
                        <app-callout type="success" title="{{'enabled' | i18n}}" icon="fa-check-circle">
                            {{'twoStepLoginProviderEnabled' | i18n}}
                        </app-callout>
                        <strong>{{'email' | i18n}}:</strong> {{email}}
                    </ng-container>
                    <ng-container *ngIf="!enabled">
                        <p class="d-flex">
                            <span class="mr-3">{{'twoFactorEmailDesc' | i18n}}</span>
                            <img src="images/1.png" class="float-right ml-auto" alt="">
                        </p>
                        <div class="form-group">
                            <label for="email">1. {{'twoFactorEmailEnterEmail' | i18n}}</label>
                            <input id="email" type="text" name="Email" class="form-control" [(ngModel)]="email" required inputmode="email" appInputVerbatim="false">
                        </div>
                        <div class="mb-3 d-flex">
                            <button #sendBtn type="button" class="btn btn-outline-primary btn-sm btn-submit align-self-start" (click)="sendEmail()" [appApiAction]="emailPromise"
                                [disabled]="sendBtn.loading">
                                <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                                <span>{{'sendEmail' | i18n}}</span>
                            </button>
                            <span class="text-success ml-3" *ngIf="sentEmail">
                                {{'verificationCodeEmailSent' | i18n : sentEmail}}
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="token">2. {{'twoFactorEmailEnterCode' | i18n}}</label>
                            <input id="token" type="text" name="Token" class="form-control" [(ngModel)]="token" required appInputVerbatim>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
                        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                        <span *ngIf="!enabled">{{'enable' | i18n}}</span>
                        <span *ngIf="enabled">{{'disable' | i18n}}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{'close' | i18n}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
