<div class="modal fade">
    <div class="modal-dialog">
        <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
            <div class="modal-header">
                <h2 class="modal-title">
                    {{'shareSelected' | i18n}}
                </h2>
                <button type="button" class="close" data-dismiss="modal" attr.aria-label="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'shareManyDesc' | i18n}}</p>
                <p>{{'shareSelectedItemsCountDesc' | i18n: this.ciphers.length : shareableCiphers.length : nonShareableCount}}</p>
                <div class="form-group">
                    <label for="organization">{{'organization' | i18n}}</label>
                    <select id="organization" name="OrganizationId" [(ngModel)]="organizationId" class="form-control" (change)="filterCollections()">
                        <option *ngFor="let o of organizations" [ngValue]="o.id">{{o.name}}</option>
                    </select>
                </div>
                <div class="d-flex">
                    <h3>{{'collections' | i18n}}</h3>
                    <div class="ml-auto d-flex" *ngIf="collections && collections.length">
                        <button type="button" (click)="selectAll(true)" class="btn btn-link btn-sm py-0">
                            {{'selectAll' | i18n}}
                        </button>
                        <button type="button" (click)="selectAll(false)" class="btn btn-link btn-sm py-0">
                            {{'unselectAll' | i18n}}
                        </button>
                    </div>
                </div>
                <div *ngIf="!collections || !collections.length">
                    {{'noCollectionsInList' | i18n}}
                </div>
                <table class="table table-hover table-list mb-0" *ngIf="collections && collections.length">
                    <tbody>
                        <tr *ngFor="let c of collections; let i = index" (click)="check(c)">
                            <td class="table-list-checkbox">
                                <input type="checkbox" [(ngModel)]="c.checked" name="Collection[{{i}}].Checked" appStopProp>
                            </td>
                            <td>
                                {{c.name}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-submit manual" [disabled]="form.loading || !canSave" [ngClass]="{loading:form.loading}">
                    <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
                    <span>{{'save' | i18n}}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" title="{{'cancel' | i18n}}">{{'cancel' | i18n}}</button>
            </div>
        </form>
    </div>
</div>
