<div class="container page-content">
    <div class="row">
        <div class="col-3">
            <app-vault-groupings (onAllClicked)="clearGroupingFilters()" (onFavoritesClicked)="filterFavorites()" (onCipherTypeClicked)="filterCipherType($event)"
                (onFolderClicked)="filterFolder($event.id)" (onAddFolder)="addFolder()" (onEditFolder)="editFolder($event.id)"
                (onCollectionClicked)="filterCollection($event.id)" (onSearchTextChanged)="filterSearchText($event)">
            </app-vault-groupings>
        </div>
        <div class="col-6">
            <div class="page-header d-flex">
                <h1>
                    {{'myVault' | i18n}}
                    <small #actionSpinner [appApiAction]="ciphersComponent.actionPromise">
                        <i *ngIf="actionSpinner.loading" class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}"></i>
                    </small>
                </h1>
                <div class="ml-auto d-flex">
                    <div class="dropdown mr-2" appListDropdown>
                        <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="bulkActionsButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-cog"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="bulkActionsButton">
                            <a class="dropdown-item" href="#" appStopClick (click)="bulkMove()">
                                <i class="fa fa-fw fa-share"></i>
                                {{'moveSelected' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick (click)="bulkShare()">
                                <i class="fa fa-fw fa-share-alt"></i>
                                {{'shareSelected' | i18n}}
                            </a>
                            <a class="dropdown-item text-danger" href="#" (click)="bulkDelete()">
                                <i class="fa fa-fw fa-trash-o"></i>
                                {{'deleteSelected' | i18n}}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" appStopClick (click)="selectAll(true)">
                                <i class="fa fa-fw fa-check-square-o"></i>
                                {{'selectAll' | i18n}}
                            </a>
                            <a class="dropdown-item" href="#" appStopClick (click)="selectAll(false)">
                                <i class="fa fa-fw fa-minus-square-o"></i>
                                {{'unselectAll' | i18n}}
                            </a>
                        </div>
                    </div>
                    <button type="button" class="btn btn-outline-primary btn-sm" (click)="addCipher()">
                        <i class="fa fa-plus fa-fw"></i>{{'addItem' | i18n}}
                    </button>
                </div>
            </div>
            <app-vault-ciphers (onCipherClicked)="editCipher($event)" (onAttachmentsClicked)="editCipherAttachments($event)" (onAddCipher)="addCipher()"
                (onShareClicked)="shareCipher($event)" (onCollectionsClicked)="editCipherCollections($event)">
            </app-vault-ciphers>
        </div>
        <div class="col-3">
            <div class="card border-warning mb-4" *ngIf="showUpdateKey">
                <div class="card-header bg-warning text-white">
                    <i class="fa fa-warning fa-fw"></i> {{'updateKeyTitle' | i18n}}
                </div>
                <div class="card-body">
                    <p>{{'updateEncryptionKeyShortDesc' | i18n}}</p>
                    <button class="btn btn-block btn-outline-secondary" type="button" (click)="updateKey()">
                        {{'updateEncryptionKey' | i18n}}
                    </button>
                </div>
            </div>
            <app-verify-email *ngIf="showVerifyEmail" class="d-block mb-4"></app-verify-email>
            <div class="card border-warning mb-4" *ngIf="showBrowserOutdated">
                <div class="card-header bg-warning text-white">
                    <i class="fa fa-warning fa-fw"></i> {{'updateBrowser' | i18n}}
                </div>
                <div class="card-body">
                    <p>{{'updateBrowserDesc' | i18n}}</p>
                    <a class="btn btn-block btn-outline-secondary" target="_blank" href="https://browser-update.org/update-browser.html" rel="noopener">
                        {{'updateBrowser' | i18n}}
                    </a>
                </div>
            </div>
            <div class="card border-success mb-4" *ngIf="showPremiumCallout">
                <div class="card-header bg-success text-white">
                    <i class="fa fa-star fa-fw"></i> {{'goPremium' | i18n}}
                </div>
                <div class="card-body">
                    <p>{{'premiumUpgradeUnlockFeatures' | i18n}}</p>
                    <a class="btn btn-block btn-outline-secondary" routerLink="/settings/premium">
                        {{'goPremium' | i18n}}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex">
                    {{'organizations' | i18n}}
                    <a class="ml-auto" href="https://help.bitwarden.com/article/what-is-an-organization/" target="_blank" rel="noopener" title="{{'learnMore' | i18n}}">
                        <i class="fa fa-question-circle-o"></i>
                    </a>
                </div>
                <div class="card-body">
                    <app-organizations [vault]="true"></app-organizations>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #attachments></ng-template>
<ng-template #folderAddEdit></ng-template>
<ng-template #cipherAddEdit></ng-template>
<ng-template #share></ng-template>
<ng-template #collections></ng-template>
<ng-template #bulkDeleteTemplate></ng-template>
<ng-template #bulkMoveTemplate></ng-template>
<ng-template #bulkShareTemplate></ng-template>
<ng-template #updateKeyTemplate></ng-template>
