<div class="page-header">
    <h1>{{'goPremium' | i18n}}</h1>
</div>
<app-callout type="info" *ngIf="canAccessPremium" title="{{'youHavePremiumAccess' | i18n}}" icon="fa-star">
    {{'alreadyPremiumFromOrg' | i18n}}
</app-callout>
<app-callout type="success">
    <p>{{'premiumUpgradeUnlockFeatures' | i18n}}</p>
    <ul class="fa-ul">
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpStorage' | i18n}}
        </li>
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpTwoStep' | i18n}}
        </li>
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpReports' | i18n}}
        </li>
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpTotp' | i18n}}
        </li>
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpSupport' | i18n}}
        </li>
        <li>
            <i class="fa fa-check text-success fa-li"></i>
            {{'premiumSignUpFuture' | i18n}}
        </li>
    </ul>
    <p class="text-lg" [ngClass]="{'mb-0':!selfHosted}">{{'premiumPrice' | i18n : (premiumPrice | currency:'$')}}</p>
    <a href="https://vault.bitwarden.com/#/settings/premium" target="_blank" rel="noopener" class="btn btn-outline-secondary"
        *ngIf="selfHosted">
        {{'purchasePremium' | i18n}}
    </a>
</app-callout>
<ng-container *ngIf="selfHosted">
    <p>{{'uploadLicenseFilePremium' | i18n}}</p>
    <form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
        <div class="form-group">
            <label for="file">{{'licenseFile' | i18n}}</label>
            <input type="file" id="file" class="form-control-file" name="file" required>
            <small class="form-text text-muted">{{'licenseFileDesc' | i18n : 'bitwarden_premium_license.json'}}</small>
        </div>
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
            <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
            <span>{{'submit' | i18n}}</span>
        </button>
    </form>
</ng-container>
<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate *ngIf="!selfHosted">
    <h2 class="mt-5">{{'addons' | i18n}}</h2>
    <div class="row">
        <div class="form-group col-6">
            <label for="additionalStorage">{{'additionalStorageGb' | i18n}}</label>
            <input id="additionalStorage" class="form-control" type="number" name="AdditionalStorageGb" [(ngModel)]="additionalStorage"
                min="0" max="99" step="1" placeholder="{{'additionalStorageGbDesc' | i18n}}">
            <small class="text-muted form-text">{{'additionalStorageIntervalDesc' | i18n : '1 GB' : (storageGbPrice | currency:'$') : ('year' | i18n)}}</small>
        </div>
    </div>
    <h2 class="spaced-header">{{'summary' | i18n}}</h2>
    {{'premiumMembership' | i18n}}: {{premiumPrice | currency:'$'}}
    <br> {{'additionalStorageGb' | i18n}}: {{additionalStorage || 0}} GB &times; {{storageGbPrice | currency:'$'}} = {{additionalStorageTotal
    | currency:'$'}}
    <hr class="my-3">
    <div class="text-lg">
        <strong>{{'total' | i18n}}:</strong> {{total | currency:'USD $'}} /{{'year' | i18n}}
    </div>
    <small class="text-muted font-italic">{{'paymentChargedAnnually' | i18n}}</small>
    <h2 class="spaced-header mb-4">{{'paymentInformation' | i18n}}</h2>
    <app-payment [hideBank]="true"></app-payment>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
        <span>{{'submit' | i18n}}</span>
    </button>
</form>
