<div class="page-header">
    <h1>{{'options' | i18n}}</h1>
</div>
<p>{{'optionsDesc' | i18n}}</p>
<form (ngSubmit)="submit()" ngNativeValidate>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="lockOption">{{'lockOptions' | i18n}}</label>
                <select id="lockOption" name="LockOption" [(ngModel)]="lockOption" class="form-control">
                    <option *ngFor="let o of lockOptions" [ngValue]="o.value">{{o.name}}</option>
                </select>
                <small class="form-text text-muted">{{'lockOptionsDesc' | i18n}}</small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <div class="d-flex">
                    <label for="locale">{{'language' | i18n}}</label>
                    <a class="ml-auto" href="https://help.bitwarden.com/article/localization/" target="_blank" rel="noopener" title="{{'learnMore' | i18n}}">
                        <i class="fa fa-question-circle-o"></i>
                    </a>
                </div>
                <select id="locale" name="Locale" [(ngModel)]="locale" class="form-control">
                    <option *ngFor="let o of localeOptions" [ngValue]="o.value">{{o.name}}</option>
                </select>
                <small class="form-text text-muted">{{'languageDesc' | i18n}}</small>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="disableIcons" name="DisableIcons" [(ngModel)]="disableIcons">
            <label class="form-check-label" for="disableIcons">
                {{'disableIcons' | i18n}}
            </label>
            <a href="https://help.bitwarden.com/article/website-icons/" target="_blank" rel="noopener" title="{{'learnMore' | i18n}}">
                <i class="fa fa-question-circle-o"></i>
            </a>
        </div>
        <small class="form-text text-muted">{{'disableIconsDesc' | i18n}}</small>
    </div>
    <div class="form-group">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="enableGravatars" name="enableGravatars" [(ngModel)]="enableGravatars">
            <label class="form-check-label" for="enableGravatars">
                {{'enableGravatars' | i18n}}
            </label>
            <a href="https://gravatar.com/" target="_blank" rel="noopener" title="{{'learnMore' | i18n}}">
                <i class="fa fa-question-circle-o"></i>
            </a>
        </div>
        <small class="form-text text-muted">{{'enableGravatarsDesc' | i18n}}</small>
    </div>
    <button type="submit" class="btn btn-primary">
        {{'save' | i18n}}
    </button>
</form>
