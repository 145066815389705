<div class="page-header d-flex">
    <h1>{{'eventLogs' | i18n}}</h1>
    <div class="ml-auto d-flex">
        <div class="form-inline">
            <label class="sr-only" for="start">{{'startDate' | i18n}}</label>
            <input type="datetime-local" class="form-control form-control-sm" id="start" placeholder="{{'startDate' | i18n}}" [(ngModel)]="start"
                placeholder="YYYY-MM-DDTHH:MM">
            <span class="mx-2">-</span>
            <label class="sr-only" for="end">{{'endDate' | i18n}}</label>
            <input type="datetime-local" class="form-control form-control-sm" id="end" placeholder="{{'endDate' | i18n}}" [(ngModel)]="end"
                placeholder="YYYY-MM-DDTHH:MM">
        </div>
        <button #refreshBtn [appApiAction]="refreshPromise" type="button" class="btn btn-sm btn-outline-primary ml-3" (click)="loadEvents(true)"
            [disabled]="loaded && refreshBtn.loading">
            <i class="fa fa-refresh fa-fw" [ngClass]="{'fa-spin': loaded && refreshBtn.loading}"></i>
            {{'refresh' | i18n}}
        </button>
    </div>
</div>
<i class="fa fa-spinner fa-spin text-muted" *ngIf="!loaded" title="{{'loading' | i18n}}"></i>
<ng-container *ngIf="loaded">
    <p *ngIf="!events || !events.length">{{'noEventsInList' | i18n}}</p>
    <table class="table table-hover" *ngIf="events && events.length">
        <thead>
            <tr>
                <th class="border-top-0" width="210">{{'timestamp' | i18n}}</th>
                <th class="border-top-0" width="40">
                    <span class="sr-only">{{'device' | i18n}}</span>
                </th>
                <th class="border-top-0" width="150">{{'user' | i18n}}</th>
                <th class="border-top-0">{{'event' | i18n}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let e of events">
                <td>{{e.date | date:'medium'}}</td>
                <td>
                    <i class="text-muted fa fa-lg {{e.appIcon}}" title="{{e.appName}}, {{e.ip}}"></i>
                </td>
                <td>
                    <span title="{{e.userEmail}}">{{e.userName}}</span>
                </td>
                <td [innerHTML]="e.message"></td>
            </tr>
        </tbody>
    </table>
    <button #moreBtn [appApiAction]="morePromise" type="button" class="btn btn-block btn-link btn-submit" (click)="loadEvents(false)"
        [disabled]="loaded && moreBtn.loading" *ngIf="continuationToken">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}"></i>
        <span>{{'loadMore' | i18n}}</span>
    </button>
</ng-container>
